:root {
  --primary-color: 25,60,110;
  --secondary-color: 0, 15, 40;
  --tertiary-color: 216, 63, 43;
  font-size: 62.5%;
}

*:focus {
  outline: none;
}

body {
  margin: 0;
  font-family: 'Comfortaa', 'Raleway', -apple-system, sans-serif, 'Segoe UI', 'Roboto';
  font-size: 1.4rem;
  font-weight: 600;
  color:rgba(0, 0, 0, 1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header, h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway','Eczar','Crimson Text', sans-serif;
  font-weight: 700;
  color: rgb(var(--primary-color));
  text-transform: uppercase;
  text-shadow: 2px 2px 4px #c5c5c5;
}

header {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

header::after {
  height: 10px;
  width: 50px;
  background-color: var(--primary-color);
}

/** Overwrites **/
.bg-primary {
  background-color: rgba(var(--primary-color),var(--bs-bg-opacity)) !important
}

.bg-secondary {
  background-color: rgba(var(--secondary-color),var(--bs-bg-opacity)) !important;
  color: #FFFFFF;
}

.bg-tertiary {
 background-color: rgba(var(--tertiary-color),var(--bs-bg-opacity)) !important;
}

.border-primary {
  border-color: rgb(var(--primary-color)) !important;
}

.border-secondary {
  border-color: rgb(var(--secondary-color)) !important;
}

.border-tertiary {
  border-color: rgb(var(--tertiary-color)) !important;
}

.btn {
  border: 0px;
}

.btn-primary {
  background-color: rgb(var(--primary-color)) !important;
  color: #FFFFFF;
}

.btn-secondary {
  background-color: rgb(var(--secondary-color)) !important;
  color: #FFFFFF;
}

.btn-tertiary {
  background-color: rgb(var(--tertiary-color)) !important;
  color: #FFFFFF;
}


.btn-primary:hover, .btn-secondary:hover, .btn-tertiary:hover  {
  background-color: rgb(var(--tertiary-color)) !important;
}

.text-primary {
  color: rgb(var(--primary-color)) !important;
}

.container-md {
  max-width: 1240px !important;
}

.container-sm {
  max-width: 768px !important;
}

.navbar {
  padding: 0 !important;
}

.nav-link {
  padding: 2.5rem 1rem !important;
}

.nav-link:hover {
  background-color: rgba(var(--tertiary-color), 1);
  color: #CCCCCC;
}

.row {
--bs-gutter-x: 0 !important;
}

p {
  margin-bottom: 0 !important;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea,
select
{
  outline: none;
  border: 2px solid rgb(var(--primary-color)) !important;
  border-radius: 4px;
}

/* Custom Styles */
::placeholder, select:required:invalid {
  font-size: 12px !important;
  font-style: italic
}

.servicebutton {
  position: relative !important;
  padding: 15px 60px 15px 30px;
}

.servicebutton i {
  position: absolute;
  top: 32%;
  right: 35px;
  transition: top 1s;
}

.servicebutton:hover i {
  top: 45%;
}

.fs-big {
  font-size: 3.5rem;
}

.fs-extrabig {
  font-size: calc(3rem + 100%);
  text-shadow: none;
}

.text-justify {
  text-align: justify;
  text-justify: inter-character !important;
}

.services {
  height: 300px;
}

.services .content {
  font-size: 1.6rem;
  font-weight: 800px;
  text-align: left;;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 32px;
  --bs-bg-opacity: 0.95;
  transition: background-color 2s;
}

.services .content:hover {
  --bs-bg-opacity: 0.2;
  color: transparent;
}

.services.adjacent p {
  position: relative;

}

.services img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.services.adjacent .content {
  --bs-bg-opacity: 0;
  color: transparent;
  transition: background-color 2s;
}

.services.adjacent .content:hover {
  --bs-bg-opacity: 0.8;
  color: #FFFFFF;
}

#contact {
  height: 600px;
}

.partners img {
  width: auto;
  height:60px;
  filter: grayscale(0%);
  border: 0px solid rgb(var(--primary-color), 0.2);
  background-color: rgba(var(--primary-color), 0);
  border-radius: 0px;
  padding: 1rem 2rem;
  margin: 0 2rem;
  transition: padding 0.5s;
}

.partners img:hover {
  padding: 0.5rem 2rem;
  filter: grayscale(100%);
}

section, footer {
  padding: 40px 0px;
  /*color: #FFFFFF;*/
}

footer {
  background-image: url(../public/images/dotted-world-map-bg.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center 65%;
  background-attachment: fixed;
  --bs-bg-opacity: 0.1
}

footer ul {
  padding-left: 0px;
}
footer ul li {
  list-style-type: none;
  padding-bottom: 0.5rem;
}

footer a, .copyright a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  transition: padding 1s;
}

footer a:hover {
  font-kerning: normal !important;
  color: #FFFFFF;
  font-weight: 800;
  padding-left: 5px;
}

footer .quicklinks a::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f101";
  padding-right: 1rem;
}

footer .servicelinks a::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f061";
  padding-right: 1rem;
}

/* Media Queries */

  @media only screen and (min-width: 768px) {
  .center {
    padding: 50px 150px;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  #contact {
    height: 1200px !important;
  }
}